import React, { useEffect } from "react";
import OnramperWidget from "@onramper/widget";

export default () => {

    useEffect(() => {
        const popupMessageListener = async (event: MessageEvent) => {
            console.log("popupMessageListener", event)
        }
        window.addEventListener("message", popupMessageListener)
        return () => window.removeEventListener("message", popupMessageListener)
      }, [])

      const getQueryString = (params: any = {}) => {
        if(Object.keys(params).length == 0) return ''
        return `?${Object.keys(params).map(key => key + '=' + params[key]).join('&')}`;
    }

      let options = {
        apiKey: "pk_test_x5M_5fdXzn1fxK04seu0JgFjGsu7CH8lOvS9xZWzuSM0",
        supportSell: false,
        isAmountEditable: false,
        isAddressEditable: false,
        defaultCrypto: 'MATIC',
        defaultFiat: 'EUR',
        onlyCryptos: 'MATIC',
        onlyFiat: 'EUR',
        defaultAmount: 40,
        onlyPaymentMethods: "creditCard",
        wallets: "POLYGON:0x1fDf280667866D3D9240CB1D14CcD37907eB4772",
        redirectURL: "https://localhost:3000",
        onlyGateways: 'Itez,Wyre'
    }

    return (
        // <iframe
        // src={`https://widget.onramper.com${getQueryString(options)}`}
        // height="660px"
        // width="482px"
        // title="Onramper widget"
        // allow="accelerometer; autoplay; camera; gyroscope; payment"
        // >
        // </iframe>
        <OnramperWidget
            API_KEY={"pk_prod_trQ0nGBcmU_JY41N8Tl50Q00"}
            color={"#C94B32"}
            supportSell={false}
            defaultAddrs={{"MATIC" : { address: "0x1fDf280667866D3D9240CB1D14CcD37907eB4772" }  }}
            defaultCrypto={"MATIC"}
            defaultFiat={"USD"}
            defaultAmount={40}
            supportSwap={false}
            // //defaultFiatSoft={defaultFiatSoft}
            // defaultPaymentMethod={"creditCard"}
            filters={{
                onlyCryptos: ["MATIC"],
                onlyPaymentMethods: "creditCard",
                // excludePaymentMethods: excludePaymentMethods,
                // excludeFiat: excludeFiat,
                onlyGateways: ["Itez"],
                onlyFiat: ["USD"],
                //defaultAddrs:{"POLYGON" : {"0x1fDf280667866D3D9240CB1D14CcD37907eB4772"} }
            }}
            isAddressEditable={false}
            isAmountEditable={false}
            redirectURL={"https://localhost:3000"}
            // darkMode={false}
        />
    )
}