import * as actionTypes from '../actionTypes'

export const loadSBTDAOAction = () => {
    return {
        type: actionTypes.LOAD_SBT_DAO_ACTION
    }
}

export const setActiveContract = (payload: any) => {
    return {
        type: actionTypes.SET_CONTRACT,
        payload
    }
}

export const setDAOListAction = (payload: any) => {
    return {
        type: actionTypes.SET_DAO_LIST,
        payload
    }
}
