import React from 'react';
import { get as _get } from 'lodash'
import CssBaseline from '@mui/material/CssBaseline';
import {Grid, Box, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Container } from '@mui/system';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
import LINK_SVG from 'assets/svg/ico-link.svg'
import axios from 'axios';
import Drawer from '@mui/material/Drawer';
import Account from 'components/Account';
import { TorusWalletConnectorPlugin } from "@web3auth/torus-wallet-connector-plugin";
import { useAppSelector } from 'hooks/useAppSelector';
import { useWeb3Auth } from 'services/web3Auth';
import TorusSvg from 'assets/svg/torus.svg';
import IconButton from "components/IconButton";
import DropdownSvg from 'assets/svg/dropdown-red.svg'
import CloseSVG from 'assets/svg/close.svg'
import RectangleSVG from 'assets/svg/rectangle.svg'
import { useEffect, useState } from 'react';
import { beautifyHexToken } from 'utils';
import Button from 'components/Button';
import { SUPPORTED_ASSETS } from 'constants/chain';
import { CHAIN_INFO } from 'constants/chainInfo';
import { setNetworkConfig } from 'store/actions/session';
import { useAppDispatch } from 'hooks/useAppDispatch';

const useStyles = makeStyles((theme: any) => ({
  root: {
    minHeight: '100vh',
    background: 'linear-gradient(169.22deg,#fdf7f7 12.19%,#efefef 92%);'
  },
  walletAddress: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: 22,
    color: "#EA6447",
    paddingRight: 8
  },
  walletEmail: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    fontSize: '11px !important',
    lineHeight: 22,
    paddingRight: 8
  },
  card: {
    boxShadow: "3px 5px 4px rgba(27, 43, 65, 0.05), -3px -3px 8px rgba(201, 75, 50, 0.1)",
    marginTop: 16,
    borderRadius: 6,
    padding: "24px 32px"
  },
  totalValue: {
    fontFamily:'Inter, sans-serif',
    fontStyle: "normal",
    fontWeight: '600 !important',
    fontSize: "24px !important",
    lineHeight: 33,
    color: "#1B2B41"
  },
  currentValue: {
    fontFamily:'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: '22px',
    color: '#EA6447'
  },
  currentTokenValue: {
    fontFamily:'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '16px',
    color: '#1B2B41'
  },
  strip: {
    width: 208,
    height: 3,
    backgroundColor: "#C94B32"
  },
  collectibleItem: {
    position: 'relative',
    width: "100%",
    height: 150,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display:'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  dropdown: {
    boxShadow: "inset 1px 0px 4px rgba(27, 43, 65, 0.1)",
    borderRadius: "16px 0px 0px 16px",
    padding: "6px 16px 6px 16px",
    width: 150,
    cursor: 'pointer'
  }
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<MuiAppBarProps>(({ theme }) => ({
  height: 107,
  boxShadow: 'inherit',
  backgroundColor: 'transparent',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export default ({ children } : any) => {
  const dispatch = useAppDispatch()
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { account, provider, chainId, openWallet, switchChain, web3Auth } = useWeb3Auth();
    // @ts-expect-error
  const { token, user } = useAppSelector(store => store.session) 
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [walletData, setWalletData] = useState<any>({})

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const computeBalance = async (chain: number) => {
    const userinfo = await web3Auth?.getUserInfo()
    setWalletData((prev: any) => {
      return {
        ...prev,
        walletChain: chain,
        userinfo
      }
    })
    let coinId = SUPPORTED_ASSETS[`${chain}`].id
    const balance = await provider?.getBalance(account)
    const accBalance = ethers.utils.formatEther(balance.toString())
    const request = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${coinId}`
    );
    const price = await request.data.market_data?.current_price["usd"];

    const nft = await axios.get(`https://nft.api.infura.io/networks/${chain}/accounts/${account}/assets/nfts`, 
    //@ts-ignore
    { auth: { username: process.env.REACT_APP_INFURA_KEY, password: process.env.REACT_APP_INFURA_SECRET } }).then(res => res?.data?.assets.filter(asset => asset?.metadata))

    setWalletData((prev: any) => {
      return {
        ...prev,
        balance: accBalance,
        balanceInCurrency: +accBalance * price,
        currentPrice: price,
        walletChain: chain,
        nft
      }
    })
  }

  useEffect(() => {
    if(showDrawer) {
      computeBalance(chainId)
    }
  }, [showDrawer, chainId])

  useEffect(() => {
    if(account && provider && chainId)
      computeBalance(chainId)
  }, [account, provider, chainId])

  return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar
            style={{ background: 'transparent', height: '100%', paddingLeft: 0 }}
          >
            <Grid container>
                <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                    <Box sx={{ flexGrow: 1 }}></Box>
                    { account &&  user && token && <Account onOpenWallet={() => setShowDrawer(true)}/> }
                </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Container sx={{ px:  isMobile ? 1 : 2 }} maxWidth="lg">
          { children }
        </Container>
        <Drawer
                PaperProps={{ style: { borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                sx={{ zIndex: 99999 }}
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}>
                <Box sx={{ position: 'relative', width: 575, paddingBottom: '60px', borderRadius: '20px 0px 0px 20px' }}>
                     <IconButton sx={{ position: 'fixed', right: 32, top: 32 }} onClick={() => setShowDrawer(false)}>
                        <img src={CloseSVG} />
                    </IconButton>
                    <Box sx={{ p: 6 }}>
                        <img src={TorusSvg} />
                        { account && walletData && walletData.walletChain &&
                          <>
                            <Box sx={{ mt: 4 }} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                              <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center">
                                <Typography className={classes.walletAddress}>{_get(walletData, 'userinfo.email')}</Typography>
                                <Typography className={classes.walletEmail}>{beautifyHexToken(account)}</Typography>
                                <Typography onClick={() => openWallet()} style={{ fontSize: 12, cursor: 'pointer', marginRight: 8, textDecoration: 'underline' }}>Open Wallet</Typography>
                              </Box>
                              <IconButton onClick={() => {
                                  navigator.clipboard.writeText(account);
                                  toast.success('Copied to clipboard')
                              }} style={{ marginRight: 12 }}> <img src={LINK_SVG} /></IconButton>
                            </Box>
                            <Box sx={{ mt: 8 }} className={classes.card}>
                                <Box sx={{ mb: 2 }} display="flex" flexDirection="row" justifyContent="flex-end">
                                  <Box
                                  id="demo-positioned-button"
                                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={handleClick} className={classes.dropdown} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                    <img style={{ width: 16, height: 16, objectFit: 'contain' }} src={CHAIN_INFO[walletData?.walletChain].logoUrl} />
                                    <Typography>{ CHAIN_INFO[walletData?.walletChain].label }</Typography>
                                    <img src={DropdownSvg} />
                                  </Box>
                                  <Menu
                                    style={{zIndex: 100000}}
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    {
                                      Object.keys(CHAIN_INFO).map((key: any) => {
                                        return ( <MenuItem onClick={async () => { 
                                          const chainInfo = CHAIN_INFO[key]
                                          dispatch(setNetworkConfig({ selectedChainId: key, chain: chainInfo.chainName, web3AuthNetwork: chainInfo.network }))
                                          await switchChain(chainInfo?.chainId)
                                          computeBalance(key); 
                                          handleClose() 
                                        }}>{ CHAIN_INFO[key].label }</MenuItem> )
                                      })
                                    }
                                  </Menu>
                                </Box>
                                <Box>
                                  <Typography className={classes.totalValue}>Total Value</Typography>
                                </Box>
                                <Box sx={{ my: 2  }} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                  <Typography className={classes.currentValue}>{ parseFloat(_get(walletData, 'balanceInCurrency', '0.00')).toFixed(4) } USD</Typography>
                                  <Typography className={classes.currentTokenValue}>1 { SUPPORTED_ASSETS[`${walletData?.walletChain}`].symbol } = {parseFloat( _get(walletData, 'currentPrice', '0.00')).toFixed(4) } USD</Typography>
                                </Box>
                                <Box sx={{ mt: 4 }} display="flex" flexDirection="row">
                                  <Button onClick={() => openWallet('transfer')} sx={{ mr: 1 }} fullWidth size="small" variant="outlined">TRANSFER</Button>
                                  <Button onClick={() => openWallet('topup')}  sx={{ ml: 1 }} fullWidth size="small" variant="outlined">TOP UP</Button>
                                </Box>
                            </Box>
                            <Box sx={{ my: 6 }} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                              <Box className={classes.strip} ></Box>
                            </Box>
                            <Box>
                              <Typography sx={{ mb: 1 }} style={{ fontSize: 16, fontWeight: 600 }}>Collectibles</Typography>
                              <Grid container spacing={1}>
                                {
                                  walletData?.nft && walletData.nft.length > 0 && walletData?.nft?.map((asset: any) => {
                                    return (
                                      <Grid sm={6} item>
                                        <Box className={classes.collectibleItem} style={{ backgroundImage: `url('${asset?.metadata?.image}')` }}>
                                          <Box style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}></Box>
                                          <Box sx={{ m: 1, zIndex: 1 }} display="flex" flexDirection="row">
                                            <img src={RectangleSVG} />
                                            <Typography sx={{ ml: 1 }} color="#FFF">{ asset?.metadata?.name }</Typography>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    )
                                  })
                                }
                              </Grid>
                            </Box>
                          </>
                        }
                    </Box>
                </Box>
            </Drawer>
      </Grid>
  );
}