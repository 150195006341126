import { useCallback, useEffect, useMemo, useState } from 'react'
import { throttle as _throttle, debounce as _debounce, get as _get } from 'lodash'
import { Container, Grid, Typography, Box, Paper, Menu } from "@mui/material"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Button from "components/Button";
import { getSigner } from 'utils'
import CHEERS from 'assets/svg/cheers.svg'
import { Connector } from "@web3-react/types";
import { injectedConnection } from "connection";
// import LOMADS_LOGO from 'assets/svg/lomadsfulllogo.svg'
import LOMADS_LOGO from 'assets/svg/Group 773.svg'
import LOMADS_LOGO_TEXT from 'assets/svg/Group 772.svg'
import METAMASK from 'assets/svg/metamask.svg'
import GMAIL from 'assets/images/gmail.png'
import APPLE from 'assets/images/apple.png'
import { KeyboardArrowDown } from '@mui/icons-material';
import { SUPPORTED_CHAIN_IDS, CHAIN_IDS_TO_NAMES, SupportedChainId } from 'constants/chain';
import { CHAIN_INFO } from 'constants/chainInfo';
import Web3Token from 'web3-token';
import { isChainAllowed, switchChain } from "utils/switchChain";
import { useAppSelector } from 'hooks/useAppSelector';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { createAccountAction, setTokenAction, setNetworkConfig, logoutAction } from 'store/actions/session';
import metamaskError from 'utils/metamaskError';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import web3Auth from '../../hooks/useWeb3Auth'
// import useWeb3Auth from '../../hooks/useWeb3Auth';
import { useWeb3Auth } from 'services/web3Auth';
import { WALLET_ADAPTERS } from "@web3auth/base";
import { ethers } from 'ethers';

const useStyles = makeStyles((theme: any) => ({
    root: {
        height: "100vh",
        maxHeight: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden !important'
    },
    logo: {
        width: 138,
        height: 81
    },
    cheers: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center'
    },
    metamaskButton: {
        height: '111px !important',
        cursor: 'pointer',
        alignContent: "inherit",
        background: "#fff",
        borderColor: "#c94b32",
        borderRadius: '10px !important',
        borderWidth: 0,
        filter: "drop-shadow(3px 5px 4px rgba(27,43,65,.05)) drop-shadow(-3px -3px 8px rgba(201,75,50,.1)) !important",
        margin: "10px",
        padding: 40
    },
    select: {
        background: '#FFF',
        borderRadius: '10px !important',
        boxShadow: 'none !important',
        fontSize: '16px !important',
        minWidth: 'inherit !importnt',
        padding: '0px !important'
    }
}));

export default () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const from = searchParams.get("from")
    const { token, user, selectedChainId } = useSelector((store: any) => store.session);
    const [currentChain, setCurrentChain] = useState(SupportedChainId.GOERLI)
    const [reloaded, setReloaded] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const { provider, login, account, chainId, logout, web3Auth } = useWeb3Auth();

    console.log("provider", provider)

    useEffect(() => {
        setCurrentChain(selectedChainId)
    }, [selectedChainId])

    // const chainAllowed = useMemo(() => {
    //     if(!chainId) return false;
    //     return isChainAllowed(connector, chainId)
    // }, [connector, chainId])

    // useEffect(() => {
    //     if(chainId) {
    //         if(chainAllowed)
    //             setCurrentChain(chainId)
    //         else
    //             setCurrentChain(SupportedChainId.GOERLI)
    //     }
    // }, [chainId, chainAllowed])

    useEffect(() => {
        if (token && user && account) {
            if (from)
                navigate(from)
            else
                navigate('/')
        }
    }, [token, user, account])

    // const generateToken = async () => {
    //     if (!token) {
    //       if(provider && account){
    //         const signer = getSigner(provider, account)
    //         const token = await Web3Token.sign(async (msg: string) => await signer.signMessage(msg), '365d');

    //       }
    //     }
    //   }

    const handleSwitchNetwork = _throttle(async (chain: any) => {
        const chainInfo = CHAIN_INFO[chain]
        console.log(chainInfo)
        dispatch(setNetworkConfig({ selectedChainId: chain, chain: chainInfo.chainName, web3AuthNetwork: chainInfo.network }))
        setCurrentChain(chain)
    }, 1000)

    const handleLogin = async (loginType = WALLET_ADAPTERS.METAMASK, provider: undefined | string = undefined) => {
        dispatch(logoutAction())
        await logout()
        console.log("window?.ethereum", window?.ethereum)
        // if(window?.ethereum){
        //     const chainInfo = CHAIN_INFO[+_get(window?.ethereum, 'networkVersion', 5)]
        //     console.log(chainInfo)
        //     dispatch(setNetworkConfig({ selectedChainId: +_get(window?.ethereum, 'networkVersion', 5), chain: chainInfo.chainName, web3AuthNetwork: chainInfo.network }))
        // }
        //setTimeout(async () => {
        let token = null;
        if (loginType === WALLET_ADAPTERS.METAMASK) {
            token = await login(loginType);
        } else if (loginType === WALLET_ADAPTERS.OPENLOGIN) {
            token = await login(WALLET_ADAPTERS.OPENLOGIN, provider);
        }
        if (token) {
            let userInfo = null;
            if (web3Auth?.connectedAdapterName === "openlogin")
                userInfo = await web3Auth?.getUserInfo()
            dispatch(createAccountAction({ token, userInfo }))
        }
        //}, 1000)
    }

    return (
        <>
            <Grid container className={classes.root}>
                <Grid xs={12} item display="flex" flexDirection="column" alignItems="center">
                    <Box zIndex={0} position="absolute" bottom={0}>
                        <img src={CHEERS} style={{ marginBottom: '-5px' }} />
                    </Box>
                    <Box mb={12} mt={3} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img src={LOMADS_LOGO} />
                        <img style={{ height: 30, width: 147, objectFit: 'contain', marginTop: '15px' }} src={LOMADS_LOGO_TEXT} />
                    </Box>
                    <Typography mt={2} mb={4} color="primary" variant="h2">Connect Your Wallet</Typography>
                    <Box>
                        <Button onClick={() => handleLogin(WALLET_ADAPTERS.METAMASK)} className={classes.metamaskButton} variant='contained' color='secondary'>
                            <img src={METAMASK} />
                        </Button>
                        {/* <Button onClick={() => handleLogin(WALLET_ADAPTERS.OPENLOGIN, 'google')} className={classes.metamaskButton} style={{ marginLeft: 8, minWidth: 252 }} variant='contained' color='secondary'>
                            <img style={{ width: 120 }} src={GMAIL} />
                        </Button>
                        <Button onClick={() => handleLogin(WALLET_ADAPTERS.OPENLOGIN, 'apple')} className={classes.metamaskButton} style={{ marginLeft: 8, minWidth: 252 }} variant='contained' color='secondary'>
                            <img style={{ width: 120 }} src={APPLE} />
                        </Button> */}
                    </Box>
                    {/* <Box mt={4} display="flex" flexDirection="row" alignItems="center">
                        <Typography variant='body1' fontWeight="bold" mr={2}>Select Blockchain:</Typography>
                        <Button onClick={handleClick} aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} className={classes.select} variant="contained" color="secondary" disableElevation startIcon={<img style={{ width: 18, height: 18 }} src={_get(CHAIN_INFO, `${currentChain}.logoUrl`)} />} endIcon={<KeyboardArrowDown />}>
                            {_get(CHAIN_INFO, `${currentChain}.label`)}
                        </Button>
                        <Menu
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {
                                SUPPORTED_CHAIN_IDS.map(sc =>
                                    <MenuItem style={{ textTransform: 'uppercase' }} onClick={() => { handleSwitchNetwork(sc); handleClose() }}>
                                        <img style={{ marginRight: '8px', width: 18, height: 18 }} src={CHAIN_INFO[sc].logoUrl} />{CHAIN_INFO[sc].label}</MenuItem>)
                            }
                        </Menu>
                    </Box> */}
                    <Box height={200}></Box>
                </Grid>
            </Grid>
        </>
    )
}