import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import _ from 'lodash';
import { useNavigate, useLocation } from "react-router-dom";
import axiosHttp from 'api'
import Dropzone from "components/Dropzone";
import { Container, Grid, Button, Typography, Box, Chip, FormControl, FormLabel } from "@mui/material"
import TextInput from 'components/TextInput';
import { makeStyles } from '@mui/styles';
import { useWeb3Auth } from "services/web3Auth"

const useStyles = makeStyles((theme: any) => ({
    root: {
        minHeight: "100vh",
        paddingBottom: 60,
        maxHeight: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden !important'
    },
    headerText: {
        fontFamily: 'Insignia',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 35,
        paddingTop: 159,
        paddingBottom: 35,
        textAlign: 'center',
        color: '#C94B32'
    },
    createName: {
        margin: '25px 0px 15px 0px'
    },
    centerCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyItems: 'center',
        background: '#FFFFFF',
        boxShadow: '3px 5px 4px rgba(27, 43, 65, 0.05), -3px -3px 8px rgba(201, 75, 50, 0.1)',
        borderRadius: 5,
        width: 394,
        padding: '10px 22px 22px 22px',
        minHeight: 'fit-content'
    },
    informationPerm: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'italic',
        weight: 400,
        fontSize: 14,
        textAlign: 'center',
        color: '#76808D'
    },
    description: {
        fontStyle: "italic",
        fontWeight: 400,
        fontSize: "14px",
        maxWidth: 200,
        lineHeight: "18px",
        letterSpacing: "-0.011em",
        color: "rgba(118, 128, 141, 0.5) !important"
    },
    chip: {
        backgroundColor: 'rgba(118, 128, 141, 0.05) !important',
        width: 110,
        height: 25,
        alignSelf: "flex-end",
        padding: "4px 20px",
        '& .MuiChip-label': {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '14px',
            color: 'rgba(118, 128, 141, 0.5)'
        }
    },
}));

export default () => {
    const classes = useStyles()
    const navigate = useNavigate();

    const { chainId, account, provider } = useWeb3Auth()

    const [errors, setErrors] = useState<any>({});
    const refSafeName = useRef<string>("");

    const [state, setState] = useState<any>({
        logo: null,
        daoName: '',
        daoAddress: '',
        daoDescription: ''
    })

    // useEffect(() => {
    //     if (chainId) {
    //         axiosHttp.get(`contract?chainId=${chainId}`)
    //             .then(res => {
    //                 if (res.data.length > 0)
    //                     navigate('/')
    //             })
    //     }
    // }, [chainId])

    const handleClick = () => {
        let terrors: any = {};
        if (!state.daoName) {
            terrors.daoName = " * Organisation name is required.";
        }
        if (!state.daoDescription) {
            terrors.daoDescription = " * Organisation Address is required.";
        }
        if (_.isEmpty(terrors)) {
            handleNavigate();
        } else {
            setErrors(terrors);
        }
    };

    const checkAvailability = (e: any) => {
        if (e.target.value && e.target.value !== "") {
            axiosHttp.get(`dao/${e.target.value.replace(/ /g, "-").toLowerCase()}`)
                .then(res => {
                    if (!res.data) {
                        setState((prev: any) => { return { ...prev, daoAddress: process.env.REACT_APP_URL + "/" + e.target.value.replace(/ /g, "-").toLowerCase() } })
                    }
                    else {
                        let rand = Math.floor(1000 + Math.random() * 9000);
                        axiosHttp.get(`dao/${e.target.value.replace(/ /g, "-").toLowerCase() + '-' + rand}`)
                            .then(result => {
                                rand = Math.floor(1000 + Math.random() * 9000);
                                setState((prev: any) => { return { ...prev, daoAddress: process.env.REACT_APP_URL + "/" + e.target.value.replace(/ /g, "-").toLowerCase() + '-' + rand } })
                            })
                            .catch(err => {
                                setState((prev: any) => { return { ...prev, daoAddress: process.env.REACT_APP_URL + "/" + e.target.value.replace(/ /g, "-").toLowerCase() + '-' + rand } })
                            })
                    }
                })
                .catch(err => {
                    setState((prev: any) => { return { ...prev, daoAddress: process.env.REACT_APP_URL + "/" + e.target.value.replace(/ /g, "-").toLowerCase() } })
                })
        }
        else {
            setState((prev: any) => { return { ...prev, daoAddress: '' } })
        }
    }

    const checkAvailabilityAsync = useRef(_.debounce(checkAvailability, 500)).current

    const handleDaoName = (event: any) => {
        refSafeName.current = event.target.value.replace(/[^a-z0-9 ]/gi, "");
        setErrors({})
        setState((prev: any) => { return { ...prev, daoName: refSafeName.current.toString() } })
    };

    const handleDaoDescription = (event: any) => {
        setErrors({})
        setState((prev: any) => { return { ...prev, daoDescription: event.target.value } })
    };

    const handleNavigate = () => {
        state.daoName.length >= 1 && navigate("/create-sbt", { state: state });
    };

    return (
        <Container>
            <Grid className={classes.root}>
                <Grid item sm={12}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center">
                    <Box className={classes.headerText}>
                        Your Organisation Details
                    </Box>
                    <Box className={classes.centerCard}>
                        <Box>
                            <Box>
                                <TextInput
                                    value={state?.daoName}
                                    error={errors['daoName']}
                                    helperText={errors['daoName']}
                                    onChange={(e: any) => {
                                        checkAvailabilityAsync(e)
                                        handleDaoName(e);
                                    }}
                                    sx={{ my: 1, width: '350px' }}
                                    placeholder="Name"
                                    fullWidth
                                    label="Name Your Organisation"
                                />
                            </Box>
                            <Box>
                                <TextInput
                                    value={state?.daoDescription}
                                    error={errors['daoDescription']}
                                    helperText={errors['daoDescription']}
                                    onChange={(e: any) => {
                                        handleDaoDescription(e);
                                    }}
                                    sx={{ my: 1, width: '350px' }}
                                    placeholder="Description"
                                    fullWidth
                                    label="Description"
                                />
                            </Box>
                            <Box mt={4}>
                                <FormControl fullWidth>
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <FormLabel>Import Thumbnail</FormLabel>
                                        <Chip sx={{ mr: 1 }} className={classes.chip} size="small" label="Optional" />
                                    </Box>
                                    <Typography variant="subtitle2" className={classes.description}>Suggested dimensions and format : 800x800, .svg or .png</Typography>
                                </FormControl>
                                <Box>
                                    <Dropzone
                                        value={state?.logo}
                                        onUpload={(url: string) => {
                                            setState((prev: any) => { return { ...prev, logo: url } })
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Button
                                sx={{ width: '350px', height: '40px', backgroundColor: '#C94B32', color: '#fff' }}
                                onClick={handleClick}
                            >
                                <Typography>CONTINUE</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};