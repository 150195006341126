import { Grid, Box, Typography } from "@mui/material"
import SBT_GROUP_SVG from 'assets/svg/sbt-group.svg'
// import LOMADS_LOGO_FULL_SVG from 'assets/svg/lomadsfulllogo.svg'
import LOMADS_LOGO from 'assets/svg/Group 773.svg'
import LOMADS_LOGO_TEXT from 'assets/svg/Group 772.svg'
import Button from "components/Button"
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import palette from "theme/palette";
import { useEffect } from "react";
import axiosHttp from 'api'
import { useAppSelector } from "hooks/useAppSelector";
import { useWeb3Auth } from "services/web3Auth";

const useStyles = makeStyles((theme: any) => ({
    root: {
        height: '100vh',
        position: 'relative'
    },
    rootContent: {
        zIndex: 1,
        height: '60vh',
    },
    button: {
        height: '111px !important',
        width: '247px !important',
        borderRadius: '10px !important'
    },
    title: {
        color: '#B12F15 !important',
        fontSize: '30px !important',
        fontWeight: '400 !important'
    },
    subtitle: {
        color: '#B12F15 !important',
        opacity: 0.8,
        fontSize: '18px !important',
        fontWeight: '400 !important',
        width: 400,
        textAlign: 'center',
        lineHeight: '22px !important',
        margin: '16px 0 !important'
    },
    buttonText: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '30px !important',
        lineHeight: '33px',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#C94B32'
    },
    createBtn: {
        height: '40px !important',
        width: '164px !important',
        background: '#C94B32 !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

export default () => {
    const classes = useStyles()
    const { chainId, account, provider } = useWeb3Auth()
    const navigate = useNavigate()
    // useEffect(() => {
    //     if (chainId) {
    //         axiosHttp.get(`contract?chainId=${chainId}`)
    //             .then(res => {
    //                 if (res.data.length > 0)
    //                     navigate('/')
    //             })
    //     }
    // }, [chainId])
    return (
        <Box className={classes.root}>
            <Grid container className={classes.rootContent}>
                <Grid mt={10} item sm={12} display="flex" justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img src={LOMADS_LOGO} />
                        <img style={{ height: 30, width: 147, objectFit: 'contain', marginTop: '15px' }} src={LOMADS_LOGO_TEXT} />
                    </Box>
                </Grid>
                <Grid item sm={12} display="flex" flexDirection="column" justifyContent="center">
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Typography variant="h6" className={classes.title}>Ready to create your Pass Token?</Typography>
                        <Typography variant="body2" className={classes.subtitle}>They will be linked to their owner and will be the key for so many possibilities.</Typography>
                    </Box>
                    <Box mt={3} display="flex" alignItems="center" justifyContent="center">
                        <Button onClick={() => navigate('/create-dao')} className={classes.createBtn}>
                            <Typography style={{ fontSize: '14px', color: '#fff' }}>CREATE SBT</Typography>
                        </Button>
                    </Box>
                </Grid>
                <Box style={{ width: '100%', position: 'absolute', bottom: 0, display: 'flex', justifyContent: 'center' }}>
                    <img style={{ height: '40vh' }} src={SBT_GROUP_SVG} />
                </Box>
            </Grid>
        </Box>
    )
}