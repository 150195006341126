/**
 * List of all the networks supported by the Uniswap Interface
 */
 export enum SupportedChainId {
    MAINNET = 1,
    GOERLI = 5,
    POLYGON = 137,
    CELO = 42220
  }
  
  export const CHAIN_IDS_TO_NAMES = {
    [`${SupportedChainId.MAINNET}`]: 'mainnet',
    [`${SupportedChainId.GOERLI}`]: 'goerli',
    [`${SupportedChainId.POLYGON}`]: 'polygon',
    [`${SupportedChainId.CELO}`]: 'celo'
  }
  
  /**
   * Array of all the supported chain IDs
   */
  export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
    (id) => typeof id === 'number'
  ) as SupportedChainId[]
  
  export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
    SupportedChainId.POLYGON,
    SupportedChainId.GOERLI,
    SupportedChainId.MAINNET,
    SupportedChainId.CELO
  ]
  
  export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
    SupportedChainId.MAINNET,
    SupportedChainId.POLYGON
  ]
  
  /**
   * Unsupported networks for V2 pool behavior.
   */
  export const UNSUPPORTED_V2POOL_CHAIN_IDS = [
    SupportedChainId.POLYGON
  ]
  
  /**
   * All the chain IDs that are running the Ethereum protocol.
   */
  export const L1_CHAIN_IDS = [
    SupportedChainId.MAINNET,
    SupportedChainId.GOERLI,
    SupportedChainId.POLYGON,
    SupportedChainId.CELO
  ] as const
  
  export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]
  
  
  export const GNOSIS_SAFE_BASE_URLS:any = {
    [SupportedChainId.MAINNET]: 'https://safe-transaction-mainnet.safe.global',
    [SupportedChainId.GOERLI]: 'https://safe-transaction-goerli.safe.global',
    [SupportedChainId.POLYGON]: 'https://safe-transaction-polygon.safe.global',
    [SupportedChainId.CELO]: 'https://safe-transaction-celo.safe.global'
  }
  
  export const CHAIN_GAS_STATION :any = {
    [SupportedChainId.POLYGON]: {
      url: 'https://gasstation-mainnet.matic.network/v2',
      symbol: 'GWei'
    },
    [SupportedChainId.GOERLI]: ''
  }

  export const SUPPORTED_ASSETS = {
    [`${SupportedChainId.MAINNET}`]: {
      id: "ethereum",
      symbol: "ETH",
    },
    [`${SupportedChainId.GOERLI}`]: {
      id: "ethereum",
      symbol: "ETH",
    },
    [`${SupportedChainId.POLYGON}`]: {
      id: "wmatic",
      symbol: "MATIC",
    },
    [`${SupportedChainId.CELO}`]: {
      id: "celo",
      symbol: "CELO",
    },
  };
  
  