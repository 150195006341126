import CssBaseline from '@mui/material/CssBaseline';
import { Grid, Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/system';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Account from 'components/Account';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
  root: {
    minHeight: '100vh',
    background: '#FCF6F6'
    // background: 'linear-gradient(178.31deg, #C94B32 0.74%, #A54536 63.87%);'
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<MuiAppBarProps>(({ theme }) => ({
  height: 107,
  boxShadow: 'inherit',
  backgroundColor: 'transparent',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export default ({ children }: any) => {

  const classes = useStyles();
  const navigate = useNavigate()

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar
          style={{ background: 'transparent', height: '100%', paddingLeft: 0 }}
        >
          <Grid container>
            <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
              <Box style={{ marginLeft: 32 }}>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon/>
                </IconButton>
              </Box>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Account />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        {children}
      </Container>
    </Grid>
  );
}