import {
	call,
	put,
	takeLatest
} from 'redux-saga/effects';
import * as actionTypes from 'store/actionTypes';
import { get as _get } from 'lodash';
import { loadSBTDaoService } from 'store/services/dao'

function* loadSBTDAOSaga(action:any) {
	try {
	  const { data } = yield call(loadSBTDaoService)
	  console.log('json, response', data)
		yield put({ type: actionTypes.SET_SBT_DAO, payload: data })
	} catch (e) {

	}
  }

export default function* sessionSaga() {
	yield takeLatest(actionTypes.LOAD_SBT_DAO_ACTION, loadSBTDAOSaga)
}