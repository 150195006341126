// eslint-disable-next-line no-unused-vars
import { LandingLayout, RootLayout, PrimaryLayout, DefaultLayout } from 'layouts';
import LoginPage from 'views/Login';
import SBTLandingPage from 'views/SBTLanding';
import CreateSBTPage from 'views/CreateSBT';
import CreateDAOPage from 'views/CreateDAO';
import DashboardPage from 'views/Dashboard';
import MintPage from 'views/MintSBT';
import DCAuthPage from 'views/DCAuth';
import ElementsPage from 'views/Elements';
import OnRamperPayment from 'views/OnRamperPayment';

export default [
	{
		path: '/login',
		exact: true,
		layout: LandingLayout,
		private: false,
		component: LoginPage
	},
	{
		path: '/',
		exact: true,
		layout: RootLayout,
		private: true,
		component: DashboardPage
	},
	{
		path: '/sbt',
		exact: true,
		layout: PrimaryLayout,
		private: true,
		component: SBTLandingPage
	},
	{
		path: '/mint/:contractId',
		exact: true,
		layout: DefaultLayout,
		private: false,
		component: MintPage
	},
	{
		path: '/create-sbt',
		exact: true,
		layout: PrimaryLayout,
		private: true,
		component: CreateSBTPage
	},
	{
		path: '/create-dao',
		exact: true,
		layout: PrimaryLayout,
		private: true,
		component: CreateDAOPage
	},
	{
		path: "/dcauth",
		exact: true,
		layout: PrimaryLayout,
		private: false,
		component: DCAuthPage
	},
	{
		path: '/elements',
		exact: true,
		layout: RootLayout,
		private: true,
		component: ElementsPage
	},
	{
		path: '/on-ramper-payment',
		exact: true,
		layout: LandingLayout,
		private: false,
		component: OnRamperPayment
	},
	{
		path: '/on-ramper-payment-redirect',
		exact: true,
		layout: LandingLayout,
		private: false,
		component: DashboardPage
	},
	{
		path: '/:contractId',
		exact: true,
		layout: RootLayout,
		private: true,
		component: DashboardPage
	},
];
