import produce from 'immer';
import { get as _get } from 'lodash'
import * as actionTypes from 'store/actionTypes';


export function getInitialState() {
  return {
    daoList: null,
    dao: null,
    contract: null
  };
}

const DaoReducer = (state: any = getInitialState(), action: any) =>
  produce(state, (draft: any) => {
    const { payload } = action;
    switch (action.type) {
      case actionTypes.SET_SBT_DAO: {
        draft.daoList = payload;
        if(!draft?.dao) {
          draft.dao = payload[payload.length - 1]
          draft.contract = payload[payload.length - 1]?.sbt
        }
        break;
      }
      case actionTypes.SET_CONTRACT: {
        draft.dao = payload;
        draft.contract = payload.sbt;
        break;
      }
      case actionTypes.SET_DAO_LIST: {
        draft.daoList = payload;
        break;
      }
    }
  });

export default DaoReducer;
