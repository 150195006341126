import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import Avatar from '@mui/material/Avatar';
import useENS from 'hooks/useENS';
import { useEffect, useState } from 'react';
import { beautifyHexToken } from 'utils';
import AVATAR from 'assets/svg/avatar.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from 'store/actions/session';
import { useAppSelector } from 'hooks/useAppSelector';
import { useWeb3Auth } from 'services/web3Auth';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: any) => ({
  root: { 
    cursor: 'pointer',
    height: 60,
    background: `#FFFFFF`,
    boxShadow: `3px 5px 4px rgba(27, 43, 65, 0.05), -3px -3px 8px rgba(201, 75, 50, 0.1)`,
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'row',
  },
  address: {
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '14px !important',
    lineHeight: '18px !important',
    letterSpacing: '-0.011em !important',
    color: 'rgb(144, 144, 144)',
  },
  dropdown: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid #F0F0F0'
  }
}));

export default ({ children, onOpenWallet, ...props } : any) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chainId, account, provider, logout, web3Auth } = useWeb3Auth()
  const { getENSName } = useENS();
  const [accountName, setAccountName] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () =>  setAnchorEl(null);
  useEffect(() => {
    if(account) {
      setAccountName(beautifyHexToken(account))
      getENSName(account)
      .then(ens => {
        if(ens)
          setAccountName(ens)
      })
    }
  }, [account])

  const disconnect = async () => {
    handleClose();
    try {
      await logout()
    } catch (e) {
      console.log(e)
    }
    dispatch(logoutAction())
    await localStorage.clear()
    await sessionStorage.clear()
    setTimeout(async () => {
      window.location.reload()
    }, 500)
  };

  return (
    <>
      <Box { ...props }
        onClick={handleClick} className={classes.root} sx={{ width:  !isMobile ? 223 : 110 }}>
        <Box sx={{ pl: 2 }} display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
          <Avatar sx={{ width: 30, height: 30 }} src={AVATAR} variant="square"></Avatar>
         { !isMobile && <Typography className={classes.address} sx={{ 
              mx: 1,
              width: 100,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
             }} variant="subtitle2">{ accountName }</Typography> }
        </Box>
        <Box sx={{ p: 2 }} className={classes.dropdown}>
          <IconButton disabled>
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        { isMobile &&  <MenuItem>{ accountName }</MenuItem>}
        { !isMobile && account && web3Auth && web3Auth?.connectedAdapterName === "openlogin" && <MenuItem onClick={() => { handleClose(); onOpenWallet(); }}>Wallet</MenuItem> }
        { account && <MenuItem onClick={() => { handleClose(); disconnect(); }}>Disconnect</MenuItem> }
      </Menu>
    </>
  );
}