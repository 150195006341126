import React, { useEffect, useState } from 'react';
import { pick as _pick, get as _get } from 'lodash';
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import FullScreenLoader from 'components/FullScreenLoader';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ethers } from 'ethers';
import { SupportedChainId } from 'constants/chain'
import { INFURA_NETWORK_URLS } from 'constants/infura';
import { CHAIN_INFO } from 'constants/chainInfo';
import { WALLET_ADAPTERS, CHAIN_NAMESPACES, SafeEventEmitterProvider } from "@web3auth/base";
import { useWeb3Auth } from 'services/web3Auth';

const PrivateRoute = (props: any) => {
	const dispatch = useAppDispatch()
	const { account, chainId } = useWeb3Auth();
	const { token, user } = useSelector((store: any) => store.session);
	const [authenticated, setAuthenticated] = useState<boolean | null>(null)
	
	useEffect(() => {
		if((!token) && props.private) {
			setAuthenticated(false)
		} else {
			setAuthenticated(true)
		}
	}, [account, token, user, props.private])

	if ((authenticated === null) && props.private) {
		// if (window.location.pathname !== '/')
		// 	window.location.href = `/login?from=${window.location.pathname}`
		// else
		return <FullScreenLoader />
		//window.location.href = `/login`
	} else if ((authenticated === false) && props.private) {
		// if (window.location.pathname !== '/')
		// 	window.location.href = `/login?from=${window.location.pathname}`
		// else
		window.location.href = `/login`
	}
	else if (token && props.private && (!account || !user)) {
		return <FullScreenLoader />
	} else {
		return props.orRender;
	}
};
export default PrivateRoute;
