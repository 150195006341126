export const SET_TOKEN_ACTION = 'SET_TOKEN_ACTION'
export const CREATE_ACCOUNT_ACTION = 'CREATE_ACCOUNT_ACTION'
export const CREATE_ACCOUNT_LOADING = 'CREATE_ACCOUNT_LOADING'
export const SET_USER_ACTION = 'SET_USER_ACTION'
export const SET_NETWORK_CONFIG = 'SET_NETWORK_CONFIG'
export const LOGOUT_ACTION = "LOGOUT_ACTION"

export const LOAD_SBT_DAO_ACTION = "LOAD_SBT_DAO_ACTION"
export const SET_SBT_DAO = "SET_SBT_DAO"
export const SET_CONTRACT = "SET_CONTRACT"
export const SET_DAO_LIST = "SET_DAO_LIST"