//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import theme from './theme';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import configureStore, { persistor } from 'store'
import { PersistGate } from 'redux-persist/lib/integration/react';
import routes from 'routes'
import PrivateRoute from 'components/PrivateRoute';
import Landing from 'layouts/Landing';
import PageNotFound from 'views/PageNotFound';
import { Web3AuthProvider } from "./services/web3Auth";
import { WEB3AUTH_NETWORK_TYPE } from "./constants/web3AuthNetwork";
import { CHAIN_CONFIG_TYPE } from "./constants/chainConfig";
import { Toaster } from 'react-hot-toast';
import { useAppSelector } from 'hooks/useAppSelector';
export const store = configureStore();

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const App = () => {
	// const [web3AuthNetwork, setWeb3AuthNetwork] = useState<WEB3AUTH_NETWORK_TYPE>("testnet");
	// const [chain, setChain] = useState<CHAIN_CONFIG_TYPE>("goerli");

	return (
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<PersistGate persistor={persistor}>
						<Web3AuthProvider>
							<Router basename={''}>
								<Routes>
									{routes.map((route, index) => {
										return (
											<Route
												element={
														<PrivateRoute
															orRender={
																<route.layout>
																	<route.component />
																</route.layout>
															}
															private={route.private}
														/>
												}
												path={route.path}
											/>
										);
									})}
									<Route
										element={
												<PrivateRoute orRender= {
												<Landing>
													<PageNotFound />
												</Landing>
											} 
										private={false}
										/>}
										key={'notfound'}
										path={'*'}
									/>
								</Routes>
							</Router>
						</Web3AuthProvider>
                        <Toaster containerStyle={{
                          zIndex: 10000000
                        }} position="bottom-right" />
					</PersistGate>
				</Provider>
			</ThemeProvider>
	);
};

export default App;
